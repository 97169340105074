import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { Container, Typography, Link, Theme } from "@mui/material";

const useStyles = makeStyles<Theme, { makeOfferBannerUnclicable?: boolean }>(
  (theme) => ({
    root: {
      position: "relative",
      zIndex: 10,
      order: 1,
      marginTop: "auto",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    mobileYellowBannerRoot: {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    tabContainer: {
      marginBottom: 0,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    tab: {
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(1, 2, 2.5, 2),
      textAlign: "center",
      borderRadius: 10,
      marginBottom: -15,
      "& p": {
        margin: 0,
        lineHeight: (props) =>
          props.makeOfferBannerUnclicable ? "24px" : "21px",
      },
    },
    mobileYellowBanner: {
      marginBottom: 0,
      borderRadius: 5,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      paddingBottom: theme.spacing(1),
    },
    tabOnly: {
      padding: theme.spacing(1, 2, 1, 2),
      marginBottom: -5,
    },
    label: {
      fontSize: 16,
      textTransform: "uppercase",
    },
    tabLink: {
      position: "relative",
      display: "inline-block",
      color: theme.palette.text.primary,
      textDecorationColor: theme.palette.text.primary,
    },
    addSearchFormMargin: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    moveBannerBelowHero: {
      borderRadius: 0,
    },
  }),
);

type TradingTab = {
  url: string;
  tradingTabInMobile: JSX.Element;
};

type MobileTradingTabProps = {
  className?: string;
  tradingTab?: TradingTab;
  tradingTabInMobile?: boolean;
  isBookingWidget?: boolean;
  moveSearchAndBannerBelowHero?: boolean;
  makeOfferBannerUnclicable?: boolean;
};

export const MobileTradingTab: React.FC<
  PropsWithChildren<MobileTradingTabProps>
> = ({
  className,
  tradingTab,
  isBookingWidget,
  moveSearchAndBannerBelowHero,
  makeOfferBannerUnclicable = false,
}: PropsWithChildren<MobileTradingTabProps>): JSX.Element => {
  const classes = useStyles({ makeOfferBannerUnclicable });

  return (
    <div
      className={classnames(
        classes.root,
        classes.mobileYellowBannerRoot,
        className,
      )}
    >
      <Container className={classes.tabContainer}>
        {tradingTab && (
          <div
            className={classnames(classes.tab, classes.mobileYellowBanner, {
              // [classes.tabOnly]: !isBookingWidget,
              [classes.moveBannerBelowHero]: moveSearchAndBannerBelowHero,
            })}
          >
            {tradingTab.url && !makeOfferBannerUnclicable ? (
              <Link
                className={classes.tabLink}
                href={!makeOfferBannerUnclicable && tradingTab.url}
              >
                <Typography component="div" className={classes.label}>
                  {tradingTab.tradingTabInMobile}
                </Typography>
              </Link>
            ) : (
              <Link
                href={!makeOfferBannerUnclicable && tradingTab.url}
                underline="none"
              >
                <Typography component="div" className={classes.label}>
                  {tradingTab.tradingTabInMobile}
                </Typography>
              </Link>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};
