import React from "react";
import {
  makeBannerProps,
  StandardPageProps,
  makeStandardPageProps,
  makeHtml,
} from "../../utils/common";
import { assignFeefoReviewAPITag, checkTradingTabExpiry } from "../../utils";
import BannerComponent from "../mapped-templates/BannerTemplate";
import { useEffect, useState } from "react";
import HeaderComponentTemplate from "../mapped-templates/HeaderTemplate";
import { BannerWidgetContainer } from "../mapped-templates/BannerWidgetContainerTemplate";
import PrimaryContent from "../../utils/PrimaryContent";
import PageProperties from "../PagePropertiesTemplate";
import PageHeader from "../../components/SemanticTheme/PageHeader";
import { Container, Dialog, Hidden, Typography } from "@mui/material";
import { useRouter } from "next/router";
import FooterComponentTemplate from "../mapped-templates/FooterTemplate";
import ContentArea from "../../utils/ContentArea";
import {
  useIsAuthenticated,
  useIsAuthenticating,
  useLoginPageUrlSelector,
} from "@/store/selectors";
import { MobileTradingTab } from "@components/SemanticTheme/MobileTradingTab";
import { MappedTemplateExperimentType } from "src/types/Content";
import {
  BasicPageEntityResponseCollection,
  BasicPagePrimaryContentDynamicZone,
  FooterEntityResponseCollection,
  HeaderEntityResponseCollection,
  SeoStructuredDataEntityResponse,
} from "src/graphql/generated-strapi/types";
import { ParsedUrlQuery } from "querystring";
import { updateAbsoluteLink } from "@components/Utils";
import { LocationPromoPopUp } from "@components/SemanticTheme/LocationPromoPopUp";
import { useCookies } from "react-cookie";

interface BasicPageProps {
  rest: {
    strapiContent: {
      data: {
        basicPages: BasicPageEntityResponseCollection;
      };
    };
    headerContent: {
      data: { headers: HeaderEntityResponseCollection };
    };
    footerContent: {
      data: { footers: FooterEntityResponseCollection };
    };
    seoStructuredData: {
      data: {
        seoStructuredData: SeoStructuredDataEntityResponse;
      };
    };
    bslData?: any;
    experiments?: { [key in string]: string | boolean };
    hideSubscribeSticky?: boolean;
    queryData?: ParsedUrlQuery;
    headers: any;
  };
}
enum ExperimentToIndex {
  USPS = 0,
  FOREST_SUMMARY = 1,
  FOREST_PODS = 2,
  DISCOVER_GLENSTRESS = 3,
  MIDWEEK_BREAKS = 4,
  EXTRAS_EXPERIENCES = 9,
  FOREST_FEELING = 10,
  AS_FEATURED_IN = 12,
  FEEFOO_REVIEWS = 16,
}
const BasicPage = (props: BasicPageProps & MappedTemplateExperimentType) => {
  const experiments = props?.rest?.experiments;
  const seoStructuredData =
    props?.rest?.seoStructuredData?.data?.seoStructuredData?.data?.attributes;
  const basicPageCmsData =
    props?.rest?.strapiContent?.data?.basicPages?.data?.[0]?.attributes;
  const isHomePage = basicPageCmsData?.PageRoute === "/";
  const bannerProps = makeBannerProps(basicPageCmsData || {});
  const tradingTabProps = basicPageCmsData?.TradingTab?.data?.attributes;
  const bannerComponentRef = basicPageCmsData?.BannerComponent;
  const isBookingWidget =
    bannerComponentRef && !!Object.keys(bannerComponentRef)?.length;
  const isBookingWidgetInBanner =
    bannerComponentRef?.[0]?.__typename === "ComponentHelpersBookingWidget";
  const headerProps =
    props?.rest?.headerContent?.data?.headers?.data?.[0]?.attributes;
  const footerProps =
    props?.rest?.footerContent?.data?.footers?.data?.[0]?.attributes;
  const locationPinText = basicPageCmsData?.LocationPinText;
  const locationPinIcon =
    basicPageCmsData?.LocationPinIcon?.data?.attributes?.url;
  const pageImgQuality = basicPageCmsData?.ImageQuality;
  const primaryContent = basicPageCmsData?.PrimaryContent;
  const stickyBannerProps =
    basicPageCmsData?.StickyBanner?.data?.attributes || null;
  const pageProperties: StandardPageProps = makeStandardPageProps(
    basicPageCmsData?.seo || {},
  );
  const faqComponentContent = basicPageCmsData?.PrimaryContent?.filter((item) =>
    item?.hasOwnProperty("faq_comp"),
  );
  pageProperties["faqComponentContent"] = faqComponentContent;
  const bslData = props?.rest?.bslData ?? null;
  assignFeefoReviewAPITag(pageProperties, basicPageCmsData);
  pageProperties["ShowSubscribeModal"] = basicPageCmsData?.ShowSubscribeModal;

  if (stickyBannerProps && headerProps)
    headerProps["stickyBannerProps"] = stickyBannerProps;
  if (pageImgQuality && headerProps)
    headerProps["pageImgQuality"] = pageImgQuality;
  if (props?.rest?.headers) pageProperties["nextHeaders"] = props.rest.headers;
  if (!!seoStructuredData)
    pageProperties["seoStructuredData"] = seoStructuredData;

  const [showElement, setShowElement] = useState(false);

  const executeAfterTwoSeconds = () => {
    setTimeout(() => {
      setShowElement(true);
    }, 2000);
  };

  useEffect(() => {
    executeAfterTwoSeconds();
  }, []);

  const [cookies] = useCookies(["hideGlentressPopUp", "hideGarwnantPopUp"]);
  const showGlenstressHomePopUp =
    experiments?.homePagePopUpGlentress &&
    !cookies?.hideGlentressPopUp &&
    showElement;
  const showGarwnantPopUp =
    experiments?.garwnantPopUp && !cookies?.hideGarwnantPopUp && showElement;
  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticating = useIsAuthenticating();
  const loginUrl = useLoginPageUrlSelector();
  const parsedText = (text?: string) => {
    return text ? (
      <div dangerouslySetInnerHTML={makeHtml(text as string)} />
    ) : undefined;
  };
  const [expPrimaryContent, setExpPrimaryContent] =
    useState<BasicPagePrimaryContentDynamicZone[]>(primaryContent);
  const [inspireMePrimaryContent, setInspireMePrimaryContent] =
    useState<BasicPagePrimaryContentDynamicZone[]>(primaryContent);
  const [primaryContentForExperiments, setPrimaryContentForExperiments] =
    useState<BasicPagePrimaryContentDynamicZone[]>([]);

  const showPaymentMethods = experiments?.showPaymentMethods;
  const moveInspireMe = experiments?.moveFeaturedInAboveInspireMe;
  const excludePayMonthly = experiments?.excludePayMonthly;

  const containsMonthlyPayment = (obj: any): boolean => {
    if (typeof obj === "string") {
      return (
        obj.toLowerCase().includes("monthly payment") ||
        obj.toLowerCase().includes("pay monthly")
      );
    }
    if (Array.isArray(obj)) {
      return obj.some(containsMonthlyPayment);
    }
    if (typeof obj === "object" && obj !== null) {
      return Object.values(obj).some(containsMonthlyPayment);
    }
    return false;
  };

  const filterUSPsForExcludePayMonthly = (content: any[]): any[] => {
    return content.map((item) => {
      if (item.USPContainers && item.USPContainers.data) {
        item.USPContainers.data = item.USPContainers.data.map((container) => {
          if (
            container.attributes &&
            container.attributes.USPs &&
            container.attributes.USPs.data
          ) {
            container.attributes.USPs.data =
              container.attributes.USPs.data.filter(
                (usp) => !containsMonthlyPayment(usp),
              );
          }
          return container;
        });
      }
      return item;
    });
  };

  if (!!excludePayMonthly) {
    filterUSPsForExcludePayMonthly(primaryContent);
  }

  useEffect(() => {
    const priamryContentForExp = hidePrimaryContentBasedOnExperiments(
      primaryContent,
      experiments,
    );
    setPrimaryContentForExperiments(priamryContentForExp);
  }, [experiments]);

  const hidePrimaryContentBasedOnExperiments = (
    content: BasicPagePrimaryContentDynamicZone[],
    experiments: {
      [x: string]: string | boolean;
    },
  ) => {
    const indicesToHide = [];

    if (!!experiments?.hideUSPsFromHome)
      indicesToHide?.push(ExperimentToIndex.USPS);
    if (!!experiments?.hideForestSummary)
      indicesToHide?.push(ExperimentToIndex.FOREST_SUMMARY);
    if (!!experiments?.hideFeaturePods)
      indicesToHide?.push(ExperimentToIndex.FOREST_PODS);
    if (!!experiments?.hideDiscoverGlentress)
      indicesToHide?.push(ExperimentToIndex.DISCOVER_GLENSTRESS);
    if (!!experiments?.hideMidWeekBreaks)
      indicesToHide?.push(
        ExperimentToIndex.MIDWEEK_BREAKS,
        ExperimentToIndex.MIDWEEK_BREAKS + 1,
      );
    if (!!experiments?.hideFeefoo)
      indicesToHide?.push(ExperimentToIndex.FEEFOO_REVIEWS);
    if (!!experiments?.hideExtrasExperiences)
      indicesToHide?.push(ExperimentToIndex.EXTRAS_EXPERIENCES);
    if (!!experiments?.hideForestFeeling)
      indicesToHide?.push(
        ExperimentToIndex.FOREST_FEELING,
        ExperimentToIndex.FOREST_FEELING + 1,
      );
    if (!!experiments?.hideAsFeaturedIn)
      indicesToHide?.push(ExperimentToIndex.AS_FEATURED_IN);

    return (
      indicesToHide?.length > 0 &&
      content?.filter((_, index) => !indicesToHide.includes(index))
    );
  };

  //For CRO:FR-526
  const rearrangeContent = (
    primaryContent: BasicPagePrimaryContentDynamicZone[],
  ): BasicPagePrimaryContentDynamicZone[] => {
    const targetIndex = primaryContent.findIndex((element) =>
      element?.["textAreas"]?.data.some(
        (textArea) =>
          textArea.attributes.Name === "Homepage-As seen in - rich text slim",
      ),
    );

    const referenceIndex = primaryContent.findIndex((element) =>
      element?.["richCards"]?.data.some(
        (card) => card.attributes.Title === "Inspire me...",
      ),
    );

    if (targetIndex !== -1 && referenceIndex !== -1) {
      const [targetElement] = primaryContent.splice(targetIndex, 1);

      primaryContent.splice(referenceIndex, 0, targetElement);
    }

    return primaryContent;
  };

  useEffect(() => {
    if (!!moveInspireMe) {
      setInspireMePrimaryContent(
        rearrangeContent(
          showPaymentMethods ? primaryContent : expPrimaryContent,
        ),
      );
    }
  }, []);
  //

  //For CRO:FR-494
  useEffect(() => {
    const currPrimaryContent = moveInspireMe
      ? inspireMePrimaryContent
      : primaryContent;
    const ExpPrimaryContenWithOutRichTextForPaymentMethods =
      currPrimaryContent.filter((component) => {
        const isUnwantedRichTextArea: BasicPagePrimaryContentDynamicZone[] =
          component["textAreas"]?.data.some(
            (textArea) =>
              textArea.attributes.Name ===
                "Pay-monthly-Payment options intro" ||
              textArea.attributes.Name === "Core-spacer - rich text",
          );
        return !isUnwantedRichTextArea;
      });
    const ExpPrimaryContentWithoutPaymentMethods =
      ExpPrimaryContenWithOutRichTextForPaymentMethods &&
      ExpPrimaryContenWithOutRichTextForPaymentMethods.filter((component) => {
        const isUnwantedDescriptionCard: BasicPagePrimaryContentDynamicZone[] =
          component["descriptionCard"]?.data.some(
            (descriptionCard) =>
              descriptionCard.attributes.Name ===
              "Pay-monthly-description card container - 3 ways to pay",
          );
        return !isUnwantedDescriptionCard;
      });
    setExpPrimaryContent(ExpPrimaryContentWithoutPaymentMethods);
  }, []);
  //

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      const pathname = router.asPath;
      if (basicPageCmsData?.AccountAuthenticated)
        !!loginUrl && router.push(`${loginUrl}?returnUrl=${pathname}`);
      return;
    }
  }, [isAuthenticated, isAuthenticating, loginUrl]);

  let nextHeaders: any;
  if (!!props?.rest?.headers) {
    nextHeaders = JSON.parse(props?.rest?.headers);
  }

  const moveSearchAndBannerBelowHero =
    !!experiments?.moveSearchAndBannerBelowHero;

  return (
    <>
      <PageProperties {...pageProperties} />
      {bannerProps && (
        <BannerComponent
          isBookingWidget={isBookingWidget}
          queryData={props?.rest?.queryData}
          additionalProps={experiments}
          locationPinIcon={locationPinIcon}
          locationPinText={locationPinText}
          {...bannerProps}
        >
          {headerProps && (
            <HeaderComponentTemplate
              content={headerProps}
              additionalProps={experiments}
              hideSubscribeSticky={props?.rest?.hideSubscribeSticky}
            />
          )}
          <Hidden
            mdDown={moveSearchAndBannerBelowHero && isBookingWidgetInBanner}
          >
            {tradingTabProps?.TradingTabInMobile &&
              checkTradingTabExpiry(bannerProps) && (
                <MobileTradingTab
                  tradingTab={{
                    url: updateAbsoluteLink(
                      bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                    ),
                    tradingTabInMobile: parsedText(
                      tradingTabProps?.TradingTabInMobile,
                    ),
                  }}
                  isBookingWidget={isBookingWidget}
                  makeOfferBannerUnclicable={
                    !!experiments?.makeOfferBannerUnclicable
                  }
                />
              )}
            {bannerComponentRef && isBookingWidget && (
              <BannerWidgetContainer>
                <ContentArea
                  content={bannerComponentRef}
                  pageImgQuality={pageImgQuality}
                  additionalProps={experiments}
                />
              </BannerWidgetContainer>
            )}
          </Hidden>
        </BannerComponent>
      )}
      {moveSearchAndBannerBelowHero && isBookingWidgetInBanner && (
        <Hidden mdUp>
          {tradingTabProps?.TradingTabInMobile &&
            checkTradingTabExpiry(bannerProps) && (
              <MobileTradingTab
                tradingTab={{
                  url: updateAbsoluteLink(
                    bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                  ),
                  tradingTabInMobile: parsedText(
                    tradingTabProps?.TradingTabInMobile,
                  ),
                }}
                isBookingWidget={isBookingWidget}
                moveSearchAndBannerBelowHero={
                  !!experiments?.moveSearchAndBannerBelowHero &&
                  isBookingWidgetInBanner
                }
                makeOfferBannerUnclicable={
                  !!experiments?.makeOfferBannerUnclicable
                }
              />
            )}
          {bannerComponentRef && isBookingWidget && (
            <BannerWidgetContainer>
              <ContentArea
                content={bannerComponentRef}
                pageImgQuality={pageImgQuality}
                additionalProps={experiments}
              />
            </BannerWidgetContainer>
          )}
        </Hidden>
      )}
      {!!basicPageCmsData?.Title ? (
        <Container>
          <PageHeader
            title={basicPageCmsData?.Title || ""}
            subtitle={basicPageCmsData?.PageDescription || ""}
          />
          {primaryContent && (
            <PrimaryContent
              primaryContent={
                primaryContentForExperiments?.length > 0
                  ? primaryContentForExperiments
                  : !!!showPaymentMethods
                    ? expPrimaryContent
                    : moveInspireMe
                      ? inspireMePrimaryContent
                      : primaryContent
              }
              bslData={bslData}
              pageImgQuality={pageImgQuality}
              additionalProps={experiments}
            />
          )}
        </Container>
      ) : (
        primaryContent && (
          <PrimaryContent
            primaryContent={
              primaryContentForExperiments?.length > 0
                ? primaryContentForExperiments
                : !!!showPaymentMethods
                  ? expPrimaryContent
                  : moveInspireMe
                    ? inspireMePrimaryContent
                    : primaryContent
            }
            bslData={bslData}
            pageImgQuality={pageImgQuality}
            additionalProps={experiments}
            isHomePage={isHomePage}
          />
        )
      )}

      {footerProps && (
        <FooterComponentTemplate
          content={{
            ...footerProps,
            experiments,
            isHomePage,
          }}
        />
      )}
      {showGlenstressHomePopUp && (
        <LocationPromoPopUp
          title="Introducing Glentress Forest"
          discription={
            <Typography>
              Discover our newest location set in 3,800 acres of Scottish
              woodland. Whether you're embracing adventure or seeking space to
              unwind, Glentress Forest has it all.
            </Typography>
          }
          imgSrc={require("../../stories/assets/locations/glentress_forest.jpg")}
          ctaLabel="Book now for winter 2024"
          ctaUrl="/locations/scotland/glentress-forest#bookingWidget"
          videoUrl="https://assets.forestholidays.co.uk/damprodblob/assets/forest_holidays_teaser_c3a3a496ae.mp4"
          autoplayVideo
          cookieName="hideGlentressPopUp"
        />
      )}
      {showGarwnantPopUp && (
        <LocationPromoPopUp
          title="Brecon Beacons"
          discription={
            <Typography>
              Escape to a cabin surrounded by 400 acres of Welsh woodland.
              Bordered by mountains and moorlands, our location at Garwnant is a
              living landscape where wildlife thrives.
            </Typography>
          }
          imgSrc="https://assets.forestholidays.co.uk/damprodblob/assets/garwnant_7fdbc14f7d.jpg"
          ctaLabel="Book now for 2025"
          ctaUrl="/locations/wales/garwnant-brecon-beacons"
          videoUrl="https://assets.forestholidays.co.uk/damprodblob/assets/garwnant_e8c519c0b5.mp4"
          autoplayVideo
          cookieName="hideGarwnantPopUp"
          buttonStyle="light"
        />
      )}
    </>
  );
};

export default BasicPage;
